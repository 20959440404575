import React from 'react';
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaFacebookF } from "react-icons/fa";
import { GoMarkGithub } from "react-icons/go";
import { FaLinkedinIn } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";




const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["WordPress Developer.", "Front-End Developer.", "Web Designer."],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h1 className="text-4xl font-bold text-[#273849] dark:text-white tracking-wide">
          Hi, my name is <span className="text-designColor dark:text-DarkDesignColor capitalize">Beksultan Abdykalykov</span>
        </h1>
        <h2 className="text-4xl font-bold text-[#03a138] tracking-wide">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="_"
            cursorColor="#050dff"
          />
        </h2>
        <p className="text-2xl text-[#273849] text-justify dark:text-white font-bodyFont leading-6 tracking-wide">
        Creative Web Developer, with the ability to find competent solutions, in the
        right place at the right time. Decreased rework by 20% and costs by 15%.
        Experienced in developing websites from scratch as well as enhancing
        existing ones to improve performance and user experience. Developed an
        e-commerce customer web portal, website for advertising, and landing
        formats. 
        </p>
         <span className=" dark:text-[#273849] hover:dark:text-DarkDesignColor mt-4">
              <a 
                href="https://drive.google.com/file/d/1yjDALlzg7Vshmf5xaOU2SjYedWJWSTZc/view?usp=share_link"
                target="_blank" rel="noopener noreferrer"
                type="button" 
                className="w-64 px-4 py-3 bg-transparent rounded-md hover:bg-designColor text-designColor hover:text-white dark:hover:text-white dark:text-DarkDesignColor dark:hover:bg-DarkDesignColor border-4 border-designColor dark:border-DarkDesignColor hover:border-transparent rounded outline-none focus:ring-4 shadow-lg transform active:scale-x-75 transition-transform  flex"
                >
                <svg className="h-9 w-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>

                <span className="ml-2 text-2xl uppercase font-medium">Download CV</span>
                </a>
        </span>
      </div>
      
      <div className="flex flex-col  xl:flex-row gap-4 lgl:gap-0 justify-between -mt-8">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4 dark:text-white">
            Follow me in
          </h2>
          <div className="flex gap-4 ">
            <span className="bannerIcon dark:hover:text-DarkDesignColor text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-2xl px-5 py-2.5 text-center mr-2 mb-2">
              <a href="https://www.facebook.com/jsbenzema.benzema.5/" target="_blank" rel="noopener noreferrer">
              <FaFacebookF  />
              </a>
            </span>
            <span className="bannerIcon dark:hover:text-DarkDesignColor text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-medium rounded-lg text-2xl px-5 py-2.5 text-center mr-2 mb-2">
              <a href="https://twitter.com/BBekca389" target="_blank" rel="noopener noreferrer">
              <BsTwitter />
              </a>
            </span>
            <span className="bannerIcon dark:hover:text-DarkDesignColor text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-2xl px-5 py-2.5 text-center mr-2 mb-2">
              <a href="https://github.com/Bekksultan/" target="_blank" rel="noopener noreferrer">
              <GoMarkGithub  />
              </a>
            </span>
            <span className="bannerIcon dark:hover:text-DarkDesignColor text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-2xl px-5 py-2.5 text-center mr-2 mb-2">
              <a href="https://www.linkedin.com/in/beksultan-abdykalykov-7575a3147/" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
              </a>
            </span>
           
          </div>
        </div>

      </div>
    </div>
  );
}

export default LeftBanner