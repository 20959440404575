export const navLinksdata = [
    {
      _id: 1001,
      title: "Home",
      link: "home",
    },
    {
      _id: 1002,
      title: "Services",
      link: "services",
    },
    {
      _id: 1003,
      title: "Resume",
      link: "resume",
    },
    {
      _id: 1004,
      title: "Portfolio",
      link: "portfolio",
    },
    {
      _id: 1005,
      title: "Testimonial",
      link: "testimonials",
    },
    {
      _id: 1006,
      title: "Contact",
      link: "contact",
    },
  ];