import React from 'react'
import { AiOutlineAntDesign } from "react-icons/ai";
import { FaGlobe } from "react-icons/fa";
import { SiXdadevelopers } from "react-icons/si";
import { MdDeveloperMode, MdOutlineContentPasteSearch } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import Tag from '../setup/Tag.jsx';
import Card from './Card';

const Services = () => {
  return (
    <section
      id="services"
      className="w-full py-20 border-b-[1px] border-b-black dark:border-b-white"
    >
      <Tag tag="SERVICES" desc="24/7  support" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          tag="Web Designer"
          desc="Writing, designing or edit web page content, or coordinate with others producing content. Proven experience hand-coding HTML using semantic markup and CSS layout/positioning"
          icon={<AiOutlineAntDesign />}
        />
        <Card
          tag="Web Developer"
          desc="Creating landing pages, ecommerce using WordPress CMS. Develop. The web development process involves taking the graphical elements defined in the design process and coding them into a custom Wordpress theme."
          icon={<SiXdadevelopers />}
        />
        <Card
          tag="Apps Development "
          desc="Vuex and React Native mobile apps. Also having a responsive layout means that your website fluidly resizes for optimal viewing regardless of the screen size or device (e.g. iPhone, iPad)."
          icon={<MdDeveloperMode />}
        />
        <Card
          tag="SEO"
          desc="I look for potential issues a user might face, big or small, and make suggestions about how improvements could be made to fix that, ensuring your user is always on the right path to achieve their goals."
          icon={<MdOutlineContentPasteSearch />}
        />
        <Card
          tag="System Administrator"
          desc="Maintenance of a Linux environment. Provid on-site third level support for corporate-wide infrastructure related issues. Lead Administrator for system level performance optimization and system monitoring."
          icon={<IoSettings />}
        />
        <Card
          tag="Network Security"
          desc="Analyse data to determine any developing threat patterns. Working on several cyber security projects including the roll out of the new security measures across the business. "
          icon={<FaGlobe />}
        />
      </div>
    </section>
  );
}

export default Services