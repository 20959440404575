import React from 'react'

const Tag = ({tag, desc}) => {
  return (
    <div className="flex flex-col gap-4 font-titleFont mb-14">
      <h1 className="flex justify-center items-center text-center text-4xl uppercase md:text-5xl text-designColor dark:text-DarkDesignColor font-bold capitalize">{tag}</h1>
      <h3 className="flex justify-center items-center text-center text-2xl uppercase font-medium text-[#273849] dark:text-white tracking-wide ">
       { desc }
      </h3>
    </div>
  );
}

export default Tag