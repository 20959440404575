import React, { useState } from 'react';
import { Link } from "react-scroll";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import {logo} from "../../assets/index";
import { navLinksdata } from '../../constants';
import  ThemeContext  from '../themeContext.jsx';


const Navbar = () => {
  const [showMenu, setShowMenu]=useState(false)
  return (
    <div className="bg-gray-100 dark:bg-black w-full h-24 sticky top-0 z-50 mx-auto flex justify-between items-center font-titleFont border-b-[1px] border-b-designColor dark:border-b-DarkDesignColor">
      <div className="flex flex-col">
        <img src={logo} alt="logo" className='w-40 '/>
      </div>
      <div className="flex flex-col">
        <ThemeContext /> 
      </div>
      <div>
        <ul className="hidden mdl:inline-flex items-center gap-4 md:gap-4 ">
          {navLinksdata.map(({ _id, title, link }) => (
            <li
              className="text-base text-[#273849] dark:text-white tracking-wide cursor-pointer uppercase font-extrabold hover:text-designColor hover:dark:text-DarkDesignColor duration-300"
              key={_id}
            >
              <Link
                activeClass="active"
                to={link}
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
        <span
          onClick={() => setShowMenu(!showMenu)}
          className="text-2xl mdl:hidden  w-10 h-10 inline-flex items-center justify-center rounded-full text-designColor dark:text-DarkDesignColor cursor-pointer"
        >
          <FiMenu />
        </span>
        {showMenu && (
          <div className="w-[55%] h-screen overflow-scroll absolute top-0 left-0 bg-gray-900 p-4 scrollbar-hide">
            <div className="flex flex-col gap-8 py-2 relative">

              <ul className="flex flex-col gap-4">
                {navLinksdata.map((item) => (
                  <li
                    key={item._id}
                    className="text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300"
                  >
                    <Link
                      onClick={() => setShowMenu(false)}
                      activeClass="active"
                      to={item.link}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>

              <span
                onClick={() => setShowMenu(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-designColor duration-300 text-2xl cursor-pointer"
              >
                <MdClose />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar