import React from 'react';
import  './Float.css';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContactlessIcon from '@mui/icons-material/Contactless';



const YourAwesomeComponent = () => {
    return (
        <div className="floating-container ">
             <ContactlessIcon className="floating-button" />
            <div className="element-container">
        
            <a href="https://telegram.me/share/url?url=tel:+996777746577" target="_blank" rel="noopener noreferrer" className="float-element tooltip-left"> 
                <TelegramIcon className="telegramicon" />     
            </a>
            
            <a href="https://wa.me/996777746577" target="_blank" rel="noopener noreferrer" className="float-element tooltip-left">          
                <WhatsAppIcon className=" whatsappicon " />
            </a>

            </div>
        </div>
    )
}

export default YourAwesomeComponent