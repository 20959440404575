import React, {  useState } from 'react'
import Tag from '../setup/Tag';
import Education from './Education';
import Skills from './Skills';


const Resume = () => {
   const [educationData, setEducationData] = useState(true);
   const [skillData, setSkillData] = useState(false);

  return (
    <section id="resume" className="w-full py-20 border-b-[1px] border-b-black dark:border-b-white">
      <div className="flex justify-center items-center text-center">
        <Tag tag="MY RESUME " desc="4+ Years of experiance" />
      </div>
      <div>
        <ul className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 text-8xl font-extrabold uppercase font-serif">
          <li
            onClick={() =>
              setEducationData(true) &
              setSkillData(false) 
            }
            className={`${
              educationData
                ? "border-2 border-designColor dark:border-DarkDesignColor rounded-l-3xl text-gray-900 bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-bold "
                : "bg-emerald-600 border-transparent rounded-l-3xl"
            } resumeLi`}
          >
            Experience 
          </li>
          <li
            onClick={() =>
              setEducationData(false) &
              setSkillData(true) 
            }
            className={`${
              skillData ? " border-2 border-designColor dark:border-DarkDesignColor rounded-r-3xl text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 shadow-lg shadow-lime-500/50 dark:shadow-lg dark:shadow-lime-800/80 font-bold " : "bg-lime-600 border-transparent rounded-r-3xl"
            } resumeLi`}
          >
            Technical Skills
          </li>

        </ul>
      </div>
      {educationData && <Education />}
      {skillData && <Skills />}

    </section>
  );
}

export default Resume