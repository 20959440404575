import React from 'react';
import Navbar from './components/navbar/Navbar.jsx';
import Banner from './components/banner/Banner.jsx';
import Services from './components/Services/Services.jsx';
import Resume from './components/curriculum-vitae/Resume.jsx';
import Portfolio from './components/portfolio/Portfolio.jsx';
import Testimonials from './components/tesimonials/Testimonials.jsx';
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer.jsx';
import Float from './components/floating-action/Float.jsx';




function App() {
  return (
        
    <div className="bg-[url('../public/5590888.jpg')] dark:bg-[url('../public/abstract-polygonal.jpg')] w-full h-auto text-lightText px-4">
        <Navbar />
      <div className="max-w-screen-xl mx-auto">
        <Banner />
        <Services />
        <Resume />
        <Portfolio />
        <Testimonials />
        <Contact />
        <Footer />
        <Float />
      </div>
    </div>
  );
}

export default App;
