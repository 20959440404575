import React from 'react'
import Tag from '../setup/Tag'
import { projectCompentek, projectSolheds, projectEverlastit, projectParhaat, projectGigs, projectTilvintys, projectTapio, projectWeland, projectVinyl } from "../../assets/index";
import PortfolioCard from './PortfolioCard';

const Portfolio = () => {
  return (
    <section
      id="portfolio"
      className="w-full py-20 border-b-[2px] border-b-black dark:border-b-white"
    >
      <div className="flex justify-center items-center text-center">
        <Tag
          tag="MY PROJECTS"
          desc="My awesome works"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <PortfolioCard
          title="Tinttimaisterin"
          src={projectCompentek}
          href="https://www.tinttimaisterin.fi/"
    
        />
        <PortfolioCard
          title="Solheds"
          src={projectSolheds}
          href="https://solheds.com/en/home/"

        />
        <PortfolioCard
          title="EverlastIT"
          src={projectEverlastit}
          href="https://everlastit.bekca.in/home/"

        />
        <PortfolioCard
          title="Parhaatvaatekaupat"
          src={projectParhaat}
          href="https://parhaatvaatekaupat.fi/"
        />
        <PortfolioCard
          title="Kyrgyzguides"
          src={projectGigs}
          href="https://kyrgyzguides.com/"
 
        />
        <PortfolioCard
          title="Tinttimaisterin"
          src={projectTilvintys}
          href="https://www.tinttimaisterin.fi/"

        />
        <PortfolioCard
          title="Tapio"
          src={projectTapio}
          href="https://tapio.bekca.in/"

        />
        <PortfolioCard
          title="We Land"
          src={projectWeland}
          href="https://www.welendllc.com/"

        />
        <PortfolioCard
          title="Vinyl Record"
          src={projectVinyl}
          href="https://vinyl-xpress.com/"

        />
      </div>
    </section>
  );
}

export default Portfolio