import React from 'react'


const Card = ({tag,desc,icon}) => {
  return (
    <div className="w-full shadow-lg shadow-black  dark:shadow-sky-800/50 px-12 h-96 py-10 rounded-lg border-4 border-red-600 dark:border-cyan-600 flex items-center group hover:bg-gradient-to-t hover:from-black hover:to-[#273849] bg-[#273849] text-white hover:text-white dark:hover:text-white dark:text-[#273849] dark:bg-white transition-colors duration-100 group">
      <div className="h-80 overflow-y-hidden">
        <div className="flex h-full flex-col gap-10 translate-y-16 group-hover:translate-y-0 transition-transform duration-500">
          <div className=" h-8 flex flex-col justify-center">
        

              <span className="text-7xl mx-auto text-designColor dark:text-DarkDesignColor">{icon}</span>

          </div>
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl md:text-2xl font-titleFont text-center font-bold text-[#273849]-300 dark:text-white-300">
              {tag}
            </h1>
            <p className="text-base text-justify">{desc}</p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Card