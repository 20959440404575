import React,{useState} from 'react'
import Slider from "react-slick";
import { RiStarFill } from "react-icons/ri";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";
import Tag from '../setup/Tag'
import {  quoteUp, quoteDown } from "../../assets";


function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-14 mt-8 bg-[#0c1821] bg-transparent dark:bg-transparent hover:bg-designColor dark:hover:bg-DarkDesignColor hover:text-white dark:hover:text-white duration-300 rounded-full text-2xl text-designColor dark:text-DarkDesignColor border-designColor dark:border-DarkDesignColor border border-4 text-designColo flex justify-center items-center absolute top-0 right-0 shadow-shadowOne cursor-pointer z-10"
      onClick={onClick}
    >
      <HiArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-14 mt-8 bg-[#0c1821] bg-transparent dark:bg-transparent hover:bg-designColor dark:hover:bg-DarkDesignColor hover:text-white dark:hover:text-white duration-300 rounded-full text-2xl border-designColor dark:border-DarkDesignColor border border-4 text-designColor dark:text-DarkDesignColor flex justify-center items-center absolute top-0 right-20 shadow-shadowOne cursor-pointer z-10"
      onClick={onClick}
    >
      <HiArrowLeft />
    </div>
  );
}

const Testimonial = () => {
      const [dotActive, setDocActive] = useState(0);
     const settings = {
       dots: true,
       infinite: true,
       speed: 500,
       slidesToShow: 1,
       slidesToScroll: 1,
       nextArrow:<SampleNextArrow />,
       prevArrow:<SamplePrevArrow />,
       beforeChange: (prev, next) => {
         setDocActive(next);
       },
       appendDots: (dots) => (
         <div
           style={{
             borderRadius: "10px",
             padding: "10px",
           }}
         >
           <ul
             style={{
               display: "flex",
               gap: "15px",
               justifyContent: "center",
               marginTop: "20px",
             }}
           >
             {" "}
             {dots}{" "}
           </ul>
         </div>
       ),
       customPaging: (i) => (
         <div
           style={
             i === dotActive
               ? {
                   width: "18px",
                   height: "18px",
                   color: "blue",
                   background: "red",
                   borderRadius: "50%",
                   cursor: "pointer",

                 }
               : {
                   width: "18px",
                   height: "18px",
                   color: "blue",
                   background: "teal",
                   borderRadius: "50%",
                   cursor: "pointer",
                 }
           }
         ></div>
       ),
     };
  return (
    <section
      id="testimonials"
      className="w-full py-20 border-b-[1px] border-b-black dark:border-b-white"
    >
      <div className="flex justify-center items-center text-center">
        <Tag tag="WHAT  CUSTOMERS  SAY" desc="Testimonials" />
      </div>
      <div className="max-w-6xl mx-auto">
        {/* ================ Slider One ================== */}
        <Slider {...settings}>
          <div className="w-full">
          <img className="w-20 mb-2 lgl:w-32" src={quoteUp} alt="quoteUp" />
            <div className="w-full h-auto rounded-l-2xl rounded-r-2xl flex flex-col lgl:flex-row justify-between bg-gradient-to-r from-[#012421] to-[#23272b]">
              <div className="w-full lgl:w-[30%] h-full my-auto p-8  shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <div className="w-full flex flex-col text-center">
                  <p className="text-base text-designColor dark:text-DarkDesignColor tracking-wide mb-2">
                    Qide Ltd
                  </p>
                  <h3 className="text-2xl font-bold text-cyan-500">Anssi Karppinen</h3>
                  <p className="text-base tracking-wide text-white">
                    Project Manager 
                  </p>
                </div>
              </div>
              <div className="w-full lgl:w-[70%]  h-full flex flex-col justify-between">
                
                <div className="w-full h-[70%] rounded-r-2xl py-10 bg-gradient-to-r from-[#001715] to-[#011a18] shadow-shadowOne p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col  justify-between lgl:items-center py-6 border-b-2 border-b-designColor dark:border-b-DarkDesignColor">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide text-cyan-500 text-center">
                        App Design, WordPress website.
                      </h3>
                      <p className="text-lg text-white text-center mt-3">
                        Sep 2019 - December 2022
                      </p>
                    </div>
                    <div className="text-yellow-500 mt-2 flex gap-1 mx-auto">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-white font-medium tracking-wide leading-6 italic">
                 During the course of his employment, Beksultan proved himself willing to learn new skills whenever
                 needed and was not afraid to take on tasks that he had not carried out before. He took the time to understand my business needs and goals, and he was able to create a website that perfectly reflected my brand and message.
                 Overol, I can sincerely recommend Beksultan for similar kind of tasks and to mention his good attitude towards
                 learning.

                  </p>
                </div>
                     
              </div>
            </div>
             <img className="w-20 mt-2 lgl:w-32 ml-auto" src={quoteDown} alt="quoteDown" />
          </div>

           {/* ================ Slider Two ================== */}

          <div className="w-full">
          <img className="w-20 mb-2 lgl:w-32" src={quoteUp} alt="quoteUp" />
            <div className="w-full h-auto rounded-l-2xl rounded-r-2xl flex flex-col lgl:flex-row justify-between bg-gradient-to-r from-[#012421] to-[#23272b]">
              <div className="w-full lgl:w-[30%] h-full my-auto p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <div className="w-full flex flex-col text-center">
                  <p className="text-base text-designColor dark:text-DarkDesignColor tracking-wide mb-2">
                    IT RESTART SP LLC
                  </p>
                  <h3 className="text-2xl font-bold text-cyan-500">Enser Amanbek</h3>
                  <p className="text-base tracking-wide text-white">
                     General Director
                  </p>
                </div>
              </div>
              <div className="w-full lgl:w-[70%] h-full flex flex-col justify-between">
                
                <div className="w-full h-[70%] rounded-r-2xl rounded-r-3x py-10 bg-gradient-to-r from-[#001715] to-[#011a18] rounded-lg shadow-shadowOne p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-designColor dark:border-b-DarkDesignColor">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide text-cyan-500 text-center">
                          WordPress website.
                      </h3>
                      <p className="text-lg text-white text-center mt-3">
                        Sep 2019 - Nov 2020
                      </p>
                    </div>
                    <div className="text-yellow-500 mt-2 flex gap-1 mx-auto">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-white font-medium tracking-wide leading-6 italic">
                 I had the pleasure of working with Beksultan during their internship as a web developer at our company. He was a great addition to our team and consistently impressed us with his technical skills, creativity, and strong work ethic.

                 During their time with us, Beksultan played a key role in designing and implementing several website projects, from start to finish. He demonstrated a deep understanding of web development principles and tools, including HTML, CSS, JavaScript, and WordPress, and was able to troubleshoot and resolve issues quickly and efficiently.
                  </p>
                </div>
                     
              </div>
            </div>
             <img className="w-20 mt-2 lgl:w-32 ml-auto" src={quoteDown} alt="quoteDown" />
          </div>
          {/* ================ Slider Three ================== */}

      <div className="w-full ">
          <img className="w-20 mb-2 lgl:w-32" src={quoteUp} alt="quoteUp" />
            <div className="w-full h-auto rounded-l-2xl rounded-r-2xl rounded-l-2xl rounded-r-2xl flex flex-col lgl:flex-row justify-between bg-gradient-to-r from-[#012421] to-[#23272b]">
              <div className="w-full lgl:w-[30%] h-full my-auto p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <div className="w-full flex flex-col text-center">
                  <p className="text-base text-designColor dark:text-DarkDesignColor tracking-wide mb-2">
                    EverlastIT
                  </p>
                  <h3 className="text-2xl font-bold text-cyan-500">Daniiar Sher</h3>
                  <p className="text-base tracking-wide text-white">
                    Behavioral Marketing and Sales | B2B B2C Sales Strategist
                  </p>
                </div>
              </div>
              <div className="w-full lgl:w-[70%] h-full flex flex-col justify-between">
                
                <div className="w-full h-[70%] rounded-r-2xl py-10 bg-gradient-to-r from-[#001715] to-[#011a18] rounded-lg shadow-shadowOne p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-designColor dark:border-b-DarkDesignColor">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide text-cyan-500 text-center">
                         Web Design, Web Developer
                      </h3>
                      <p className="text-lg text-white text-center mt-3">
                         Nov 2020 - Oct 2022
                      </p>
                    </div>
                    <div className="text-yellow-500 mt-2 flex gap-1 mx-auto">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-white font-medium tracking-wide leading-6 italic">
                    Thanks to Beksultan's expertise, our website is now a key part of our business, and we regularly receive compliments on its design and functionality. 
                    Beksultan was incredibly patient with my many questions and was always available to discuss any concerns I had. Moreover, he was always transparent about the progress of the project, and his attention to detail was evident in every aspect of the website.I am glad that he was part of our friendly team and always provided help in difficult times.

                  </p>
                </div>
                     
              </div>
            </div>
             <img className="w-20 mt-2 lgl:w-32 ml-auto" src={quoteDown} alt="quoteDown" />
          </div>
          {/* ================ Slider Four ================== */}

      <div className="w-full ">
          <img className="w-20 mb-2 lgl:w-32" src={quoteUp} alt="quoteUp" />
            <div className="w-full h-auto rounded-l-2xl rounded-r-2xl flex flex-col lgl:flex-row justify-between bg-gradient-to-r from-[#012421] to-[#23272b]">
              <div className="w-full lgl:w-[30%] h-full my-auto p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">

                <div className="w-full flex flex-col text-center">
                  <p className="text-base  text-designColor dark:text-DarkDesignColor tracking-wide mb-2">
                    EverlastIT
                  </p>
                  <h3 className="text-2xl font-bold text-cyan-500">Martín Campoverde</h3>
                  <p className="text-base tracking-wide text-white">
                    Business Development Representative

                  </p>
                </div>
              </div>
              <div className="w-full lgl:w-[70%] h-full flex flex-col justify-between">
                
                <div className="w-full h-[70%] rounded-r-2xl py-10 bg-gradient-to-r from-[#001715] to-[#011a18] rounded-lg shadow-shadowOne p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-designColor dark:border-b-DarkDesignColor">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide text-cyan-500 text-center">
                         Web Design, Web Developer
                      </h3>
                      <p className="text-lg text-white text-center mt-3">
                        Nov 2020 - Oct 2022
                      </p>
                    </div>
                    <div className="text-yellow-500 mt-2 flex gap-1 mx-auto">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-white font-medium tracking-wide leading-6 italic">
                      Your accountability and reliability are truly exceptional. 
                    Besides, you always deliver on your promises and take responsibility for your work. 
                   Whenever we have encountered any challenges or setbacks, you have approached them with a positive attitude and a willingness to find solutions. At last, I have found you to be an exceptional developer and a valuable asset to our team. 
                  </p>
                </div>
                     
              </div>
            </div>
             <img className="w-20 mt-2 lgl:w-32 ml-auto" src={quoteDown} alt="quoteDown" />
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Testimonial