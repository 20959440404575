import React from 'react';
import { IoHome } from "react-icons/io5";
import { RiMailStarFill } from "react-icons/ri";
import { BsFillPhoneFill } from "react-icons/bs";
import { GiEarthAmerica } from "react-icons/gi";
import {logo} from "../../assets/index";



const Footer = () => {
  return (
  <footer className="text-center lg:text-left bg-gray-100 dark:bg-black text-[#273849] dark:text-white">
  <div className="flex justify-center items-center lg:justify-between p-6 border-b border-black dark:border-white">
    <div className="mr-12 hidden lg:block">
      <span>Get connected with us on social networks:</span>
    </div>
    <div className="flex justify-center">
      <a href="https://www.facebook.com/jsbenzema.benzema.5/" target="_blank" rel="noopener noreferrer" className="mr-6 text-[#273849] dark:text-white hover:text-designColor dark:hover:text-DarkDesignColor">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
          className="svg-inline--fa fa-facebook-f w-2.5" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512">
          <path fill="currentColor"
            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
          </path>
        </svg>
      </a>
      <a href="https://twitter.com/BBekca389" target="_blank" rel="noopener noreferrer" className="mr-6 text-[#273849] dark:text-white hover:text-designColor dark:hover:text-DarkDesignColor">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
          className="svg-inline--fa fa-twitter w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor"
            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
          </path>
        </svg>
      </a>
      <a href="https://accounts.google.com/SignOutOptions?hl=ru&continue=https://mail.google.com&service=mail" target="_blank" rel="noopener noreferrer" className="mr-6 text-[#273849] dark:text-white hover:text-designColor dark:hover:text-DarkDesignColor">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google"
          className="svg-inline--fa fa-google w-3.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
          <path fill="currentColor"
            d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z">
          </path>
        </svg>
      </a>
       <a href="https://join.skype.com/invite/MNDXayQt0exH" target="_blank" rel="noopener noreferrer" className="mr-6 text-[#273849] dark:text-white hover:text-designColor dark:hover:text-DarkDesignColor">
        <svg xmlns="http://www.w3.org/2000/svg"  className="svg-inline--fa fa-google w-4" role="img"
        fill="currentColor" viewBox="0 0 16 16"> 
        <path fill="currentColor" d="M4.671 0c.88 0 1.733.247 2.468.702a7.423 7.423 0 0 1 6.02 2.118 7.372 7.372 0 0 1 2.167 5.215c0 .344-.024.687-.072 1.026a4.662 4.662 0 0 1 .6 2.281 4.645 4.645 0 0 1-1.37 3.294A4.673 4.673 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.423 7.423 0 0 1-6.114-2.107A7.374 7.374 0 0 1 .529 8.035c0-.363.026-.724.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7.07 7.07 0 0 0 1.257.653c.492.205.873.38 1.145.523.229.112.437.264.615.448.135.142.21.331.21.528a.872.872 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.618 2.618 0 0 1-1.048-.206 11.44 11.44 0 0 1-.532-.253 1.284 1.284 0 0 0-.587-.15.717.717 0 0 0-.501.176.63.63 0 0 0-.195.491.796.796 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.113 5.113 0 0 0 2.212.419 4.554 4.554 0 0 0 1.624-.265 2.296 2.296 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.09 2.09 0 0 0-.279-1.101 2.53 2.53 0 0 0-.772-.792A7.198 7.198 0 0 0 8.486 7.3a1.05 1.05 0 0 0-.145-.058 18.182 18.182 0 0 1-1.013-.447 1.827 1.827 0 0 1-.54-.387.727.727 0 0 1-.2-.508.805.805 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096.274.079.542.177.802.293.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.69.69 0 0 0 .18-.496.717.717 0 0 0-.17-.476 1.374 1.374 0 0 0-.556-.354 3.69 3.69 0 0 0-.708-.183 5.963 5.963 0 0 0-1.022-.078 4.53 4.53 0 0 0-1.536.258 2.71 2.71 0 0 0-1.174.784 1.91 1.91 0 0 0-.45 1.287c-.01.37.076.736.25 1.063z"/> </svg>
      </a>
      <a href="https://www.linkedin.com/in/beksultan-abdykalykov-7575a3147/" target="_blank" rel="noopener noreferrer" className="mr-6 text-gray-600 dark:text-white hover:text-designColor dark:hover:text-DarkDesignColor">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in"
          className="svg-inline--fa fa-linkedin-in w-3.5" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512">
          <path fill="currentColor"
            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
          </path>
        </svg>
      </a>
      <a href="https://github.com/Bekksultan/" target="_blank" rel="noopener noreferrer" className="text-gray-600 dark:text-white hover:text-designColor dark:hover:text-DarkDesignColor">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github"
          className="svg-inline--fa fa-github w-4 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
          <path fill="currentColor"
            d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z">
          </path>
        </svg>
      </a>
    </div>
  </div>
  <div className="mx-6 py-10 text-center md:text-left">
    <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      <div className="">
      <div>
        <img src={logo} alt="logo" className='w-50 mb-5'/>
      </div>
        <p className="text-justify">
           Here you can use IT services, our businesses can streamline their operations, increase efficiency, and improve their overall performance.
        </p>
      </div>
      <div className="">
        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Products
        </h6>
        <p className="mb-4">
          <a href="https://svelte.dev/" target="_blank" rel="noopener noreferrer" className="text-[#fc4f05] font-semibold text-lg">Svelte</a>
        </p>
        <p className="mb-4">
          <a href="https://react.dev/" target="_blank" rel="noopener noreferrer" className="text-[#0281f7] font-semibold text-lg">React</a>
        </p>
        <p className="mb-4">
          <a href="https://vuejs.org/" target="_blank" rel="noopener noreferrer" className="text-[#08bf51] font-semibold text-lg">Vue</a>
        </p>
        <p>
          <a href="https://nodejs.org/en" target="_blank" rel="noopener noreferrer" className="text-[#08bf2f] font-semibold text-lg">Node</a>
        </p>
      </div>
      <div className="">
        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Layout
        </h6>
        <p className="mb-4">
          <a href="https://www.figma.com/" target="_blank" rel="noopener noreferrer" className="text-fuchsia-900 font-semibold text-lg">Figma</a>
        </p>
        <p className="mb-4">
          <a href="https://zeplin.io/" target="_blank" rel="noopener noreferrer" className="text-amber-500 font-semibold text-lg">Zeplin</a>
        </p>
        <p className="mb-4">
          <a href="https://www.adobe.com/products/illustrator.html" target="_blank" rel="noopener noreferrer" className="text-orange-600 font-semibold text-lg">Adobe Illustrator</a>
        </p>
        <p>
          <a href="https://inkscape.org/" target="_blank" rel="noopener noreferrer" className="text-slate-600 font-semibold text-lg">Inkscape</a>
        </p>
      </div>
      <div className="">
        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Contact
        </h6>
        <p className="flex items-center justify-center md:justify-start mb-4 text-lg">
          <IoHome className="w-5 mr-4"/>
          Bishkek, Kyrgyz Republic
        </p>
        <p className="flex items-center justify-center md:justify-start mb-4 text-lg">
          <RiMailStarFill className="w-5 mr-4"/>
          bekca389@gmail.com
        </p>
        <p className="flex items-center justify-center md:justify-start mb-4 text-lg">
          <BsFillPhoneFill className="w-5 mr-4"/>
         +996777746577
        </p>
        <p className="flex items-center justify-center md:justify-start text-lg">
          <GiEarthAmerica className="w-5 mr-4"/>
          <a className="hover:text-blue-600 font-semibold" href="http://portfolio.bekca.in/">portfolio.bekca.in</a>
        </p>
      </div>
    </div>
  </div>
  <div className="text-center p-6 bg-gray-200 dark:bg-black">
    <span>© 2023 Copyright:</span>
  </div>
</footer>

  

  );
}

export default Footer