import React from 'react'
import { motion } from 'framer-motion';



const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4 space-x-2">
          <h2 className="text-4xl md:text-4xl font-bold dark:text-white"> Education Quality</h2>
        </div>
        <section className="rounded-lg border-4 border-red-600 dark:border-cyan-600 bg-yellow-400 text-slate-950 dark:bg-fuchsia-700 dark:text-white">
	<div className="container max-w-full px-4 py-12 mx-auto">
		<div className="grid gap-4 mx-4 ">

			<div className="relative col-span-12 px-4 space-y-6 sm:col-span-9">
				<div className="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-[#273849] before:dark:bg-white">
					<div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-[#273849] before:dark:bg-white">
						<h3 className="text-xl font-extralight italic tracking-wide"> wayup.in <span><time className="text-base not-italic tracking-wide uppercase ">| 2017 - 2018 </time></span></h3>
						<h2 className="text-xl font-semibold tracking-wide"> Web Design Learning Path </h2>
						
						<ul className="mt-3 list-disc ml-5">
							<li>Working with the interface</li>
							<li>Dynamic content</li>
							<li>Creating UI components</li>
							<li>Working with the interface</li>
							<li>Creating a web applications</li>
							<li>Layout with Figma, Adobe Illustrator, Inkscape, Zeplin</li>
						</ul>
					</div>

					<div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-[#273849] before:dark:bg-white">
						<h3 className="text-xl font-extralight italic tracking-wide"> itproger.com <span><time className="text-base not-italic tracking-wide uppercase ">|  2016 - 2017  </time></span></h3>
						<h2 className="text-xl font-semibold tracking-wide"> Front End Web Developer Learning Path </h2>
						<ul className="mt-3 list-disc ml-5">
							<li>The complete JavaScript course</li>
							<li>PHP, MySQL courses</li>
							<li>JavaScript frameworks Vue JS and React JS</li>
							<li>Design with Bootstrap, Tailwind, Sass</li>
							<li>Responsive development</li>
							<li>Linux tutorial </li>
							<li>Learning Ajax technology</li>
							<li>Node JS and Express courses</li>
						</ul>
					</div>
					<div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-[#273849] before:dark:bg-white">
						<h3 className="text-xl font-extralight italic tracking-wide"> wayup.in <span><time className="text-base not-italic tracking-wide uppercase ">| 2017 - 2018 </time></span></h3>
						<h2 className="text-xl font-semibold tracking-wide"> Web Design Learning Path </h2>
						<ul className="mt-3 list-disc ml-5">
							<li>Operating systems</li>
							<li>Software development</li>
							<li>Database systems and big data</li>
							<li>Web programming</li>
							<li>Advanced analytics</li>
							<li>Computer Networks</li>
							<li>Telecommunications</li>
							<li>Data Science</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
      </div>
      {/* part Two */}

      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <h2 className="text-4xl md:text-4xl font-bold dark:text-white">Job Experience</h2>
        </div>
         <section className="rounded-lg border-4 border-red-600 dark:border-cyan-600 bg-yellow-400 text-slate-950 dark:bg-fuchsia-700 dark:text-white">
	<div className="container max-w-full px-4 py-12 mx-auto">
		<div className="grid gap-4 mx-4 ">

			<div className="relative col-span-12 px-4 space-y-6 sm:col-span-9">
				<div className="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-[#273849] before:dark:bg-white">
					<div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-[#273849] before:dark:bg-white">
					    <h3 className="text-xl font-extralight italic tracking-wide"> Commodimex s.r.o <span><time className="text-base not-italic tracking-wide uppercase ">| 2021 - Present  </time></span></h3>
						<h2 className="text-xl font-semibold tracking-wide"> Health System Specialist </h2>
						<h3>Full time</h3>
						<ul className="mt-3 list-disc ml-5">
							<li>Monitoring the updating of the official website https://topchange.cz/</li>
							<li>Monitor uptime, hosting, and databases to ensure site is performing at maximum capacity</li>
							<li>Helped end users to solve operating problems</li>
						</ul>
					</div>
					<div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-[#273849] before:dark:bg-white">
					    <h3 className="text-xl font-extralight italic tracking-wide"> EverlastIT <span><time className="text-base not-italic tracking-wide uppercase ">| 2020 - 2022  </time></span></h3>
						<h2 className="text-xl font-semibold tracking-wide"> Frontend  Developer  </h2>
						<h3>Part time</h3>
						<ul className="mt-3 list-disc ml-5">
							<li>Developed several websites with WordPress, PHP</li>
							<li>Developed front-end with HTML, CSS, Bootstrap and JavaScript(React JS)</li>
							<li>Built responsive, accessible and dynamic web pages</li>
							<li>Actively engaged in web creative design and development</li>
							<li>Ensured websites are accessible across many platforms, including laptops and smartphones</li>
						</ul>
					</div>
					<div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-[#273849] before:dark:bg-white">
						    <h3 className="text-xl font-extralight italic tracking-wide"> Qide Ltd <span><time className="text-base not-italic tracking-wide uppercase ">|  2019 - Present   </time></span></h3>
							<h2 className="text-xl font-semibold tracking-wide"> Web Developer </h2>
							<h3>Part time</h3>
							<ul className="mt-3 list-disc ml-5">
							<li>Designed 3 online stores</li>
							<li>Wrote internal and external design specifications.</li>
							<li>Revamped company's website via deploying responsive design and facilitated an increase in mobile users by 40%</li>
              <li>Building websites on WordPress CMS and working with plugins</li>
						</ul>
					</div>
						<div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-[#273849] before:dark:bg-white">
						    <h3 className="text-xl font-extralight italic tracking-wide"> ItRestart <span><time className="text-base not-italic tracking-wide uppercase "> | 2019 - 2020  </time></span></h3>
							<h2 className="text-xl font-semibold tracking-wide"> WordPress Developer   </h2>
							<h3>Full time</h3>
						<ul className="mt-3 list-disc ml-5">
							<li>Development expertise with custom post types, custom fields, plugins and themes for high-traffic WordPress sites</li>
							<li>PHP and HTML development outside of WordPress for landing pages, microsites, etc</li>
							<li>Fast Loading websites above 70%</li>

						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
      </div>
    </motion.div>
  );
}

export default Education