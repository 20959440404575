import React from 'react'
import ProgressBar from 'react-animated-progress-bar';
import { motion } from 'framer-motion';

const Skills = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div className="w-full lgl:w-1/2">
        <div className="py-12 font-titleFont flex flex-col gap-4">

          <h2 className="text-3xl md:text-4xl text-center uppercase font-bold dark:text-white">Design</h2>
        </div>
        <div className='className="mt-10 w-full flex flex-col gap-6'>
      
          <div className="overflow-x-hidden">
            <div className="flex justify-between">
              <span className="text-lg h-0 font-medium text-[#273849] dark:text-white">Bootstrap</span>
            </div>
            <div className="w-full">
              <ProgressBar
                width="100%"
                height="15px"
                rect
                fontColor="#03a138"
                percentage="88"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
            
                trackBorderColor="#707370"
              />
            </div>

          </div>
          <div className="overflow-x-hidden">
            <div className="flex justify-between">
              <span className="text-lg h-0 font-medium text-[#273849] dark:text-white">Tailwind</span>
            </div>
            <div className="w-full">
              <ProgressBar
                width="100%"
                height="15px"
                rect
                fontColor="#03a138"
                percentage="85"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="#707370"
              />
            </div>

          </div>
          <div className="overflow-x-hidden">
            <div className="flex justify-between">
              <span className="text-lg h-0 font-medium text-[#273849] dark:text-white">Figma</span>
            </div>
            <div className="w-full">
              <ProgressBar
                width="100%"
                height="15px"
                rect
                fontColor="#03a138"
                percentage="80"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="#707370"
              />
            </div>

          </div>
          <div className="overflow-x-hidden">
            <div className="flex justify-between">
              <span className="text-lg h-0 font-medium text-[#273849] dark:text-white">Adobe Photoshop</span>
            </div>
            <div className="w-full">
              <ProgressBar
                width="100%"
                height="15px"
                rect
                fontColor="#03a138"
                percentage="73"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="#707370"
              />
            </div>

          </div>
          <div className="overflow-x-hidden">
            <div className="flex justify-between">
              <span className="text-lg h-0 font-medium text-[#273849] dark:text-white">Adobe Illustrator</span>
            </div>
            <div className="w-full">
              <ProgressBar
                width="100%"
                height="15px"
                rect
                fontColor="#03a138"
                percentage="62"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="#707370"
              />
            </div>

          </div>

        </div>
      </div>
            <div className="w-full lgl:w-1/2">
        <div className="py-12 font-titleFont flex flex-col gap-4 ">

          <h2 className="text-3xl md:text-4xl text-center uppercase font-bold text-[#273849] dark:text-white">Development</h2>
        </div>
        <div className='className="mt-10 w-full flex flex-col gap-6'>
      
          <div className="overflow-x-hidden">
            <div className="flex justify-between">
              <span className="text-lg h-0 font-medium text-[#273849] dark:text-white">HTML</span>
            </div>
            <div className="w-full">
              <ProgressBar
                width="100%"
                height="15px"
                rect
                fontColor="#03a138"
                percentage="100"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="#707370"
              />
            </div>

          </div>
          <div className="overflow-x-hidden">
            <div className="flex justify-between">
              <span className="text-lg h-0 font-medium text-[#273849] dark:text-white">CSS</span>
            </div>
            <div className="w-full">
              <ProgressBar
                width="100%"
                height="15px"
                rect
                fontColor="#03a138"
                percentage="94"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="#707370"
              />
            </div>

          </div>
          <div className="overflow-x-hidden">
            <div className="flex justify-between">
              <span className="text-lg h-0 font-medium text-[#273849] dark:text-white">JS</span>
            </div>
            <div className="w-full">
              <ProgressBar
                width="100%"
                height="15px"
                rect
                fontColor="#03a138"
                percentage="82"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="#707370"
              />
            </div>

          </div>
          <div className="overflow-x-hidden">
            <div className="flex justify-between">
              <span className="text-lg h-0 font-medium text-[#273849] dark:text-white">ReactJS</span>
            </div>
            <div className="w-full">
              <ProgressBar
                width="100%"
                height="15px"
                rect
                fontColor="#03a138"
                percentage="80"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="#707370"
              />
            </div>

          </div>
          <div className="overflow-x-hidden">
            <div className="flex justify-between">
              <span className="text-lg h-0 font-medium text-[#273849] dark:text-white">PHP</span>
            </div>
            <div className="w-full">
              <ProgressBar
                width="100%"
                height="15px"
                rect
                fontColor="#03a138"
                percentage="74"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="#707370"
              />
            </div>

          </div>

        </div>
      </div>
    </motion.div>
  );
}

export default Skills
