import React from 'react'
import { MdDoubleArrow } from "react-icons/md";


const PortfolioCard = ({ title, src, href}) => {
  return (
    <div className="w-full h-auto rounded-lg shadow-xl shadow-black  dark:shadow-sky-800/50 flex flex-col border-4  border-red-600 dark:border-cyan-600">
  <div class="group relative block bg-black">
  <img
    alt="Developer"
    src={src}
    class="absolute inset-0 h-full w-full object-cover opacity-70 transition-opacity group-hover:opacity-20"
  />

  <div class="relative p-4 my-14 text-center sm:p-6 lg:p-8 translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
   <div class="p-4 md:p-7">
    <h1 class="text-2xl font-bold  text-white dark:text-white mb-5">
      {title}
    </h1>

  <a class="inline-flex" href={href} target="_blank" rel="noopener noreferrer">
    <span class="h-12 flex items-center text-white justify-center uppercase font-semibold px-8 border border-white hover:bg-designColor dark:hover:bg-DarkDesignColor hover:text-white transition duration-500 ease-in-out">Show More</span>
    <span class="h-12 w-12 flex-shrink-0 text-white flex items-center justify-center border border-l-0 border-white hover:bg-designColor dark:hover:bg-DarkDesignColor hover:text-white transition duration-500 ease-in-out">
        <MdDoubleArrow class="h-8 w-8" />
    </span>
</a>
  </div>


  </div>
</div>

    </div>
  );
}

export default PortfolioCard