import React, { useState } from 'react';
import Design from '../../assets/images/contact/ui-design.jpg'
import Tag from '../setup/Tag.jsx';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log('Form data:', formData);
    // Clear form fields after submission
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
  	<section
      id="contact"
      className="w-full py-20 border-b-[1px] border-b-black dark:border-b-white"
    >

     <Tag tag="CONTACT" desc="Get in touch" />
      <div class="container my-24 px-6 mx-auto bg-emerald-600 rounded-md shadow-2xl shadow-black  dark:shadow-indigo-500/50">
         <div class="flex flex-wrap">
      <div class="grow-0 shrink-0 basis-auto mb-6 md:mb-0 w-full md:w-6/12 px-3 lg:px-6">
         <img src={Design} className="w-full h-full" alt="" />
      </div>
      <div class="grow-0 shrink-0 basis-auto mb-6 md:mb-0 w-full md:w-6/12 px-3 lg:px-6">
 <form
        className="bg-designColor dark:bg-DarkDesignColor dark:text-[#273849] p-8  shadow-md max-w-2xl"
        onSubmit={handleSubmit}
      >
        <h2 className="text-3xl text-center font-semibold mb-6 text-[#273849] dark:text-white">Contact Us</h2>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Name"
          className="w-full px-4 py-2 rounded-md mb-4 focus:outline-none ring-gray focus:ring-2 dark:focus:ring-blue-500 focus:ring-red-500"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          className="w-full px-4 py-2 rounded-md mb-4 focus:outline-none focus:ring-2 dark:focus:ring-blue-500 focus:ring-red-500"
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Message"
          className="w-full px-4 py-2 rounded-md mb-6 resize-none h-40 focus:outline-none focus:ring-2 dark:focus:ring-blue-500 focus:ring-red-500"
        />
        <button
          type="submit"
          className="w-full px-4 py-2 rounded-md uppercase text-white bg-red-700 dark:bg-blue-500 hover:bg-emerald-600 dark:hover:bg-emerald-600  transition-colors duration-300"
        >
          Submit
        </button>
      </form>

      </div>
    </div>
      </div>    
    </section>
  );
};

export default ContactForm;
