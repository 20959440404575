import { useState, useEffect } from "react";
import { MdSunny } from "react-icons/md";
import { MdNightsStay } from "react-icons/md";
import '../components/theme.css';

function ThemeContext() {
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    if(window.matchMedia('(prefers-color-scheme: dark)').matches){
      setTheme('dark');
    }
    else {
      setTheme('light');
    }
  }, [])

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
        
        <div className='dark_mode'>
            <input
                className='dark_mode_input'
                type='checkbox'
                id='darkmode-toggle'
                onChange={handleThemeSwitch}
            />
            <label className='dark_mode_label' for='darkmode-toggle'>
                <MdSunny className="sun text-xl"/>
                <MdNightsStay className="moon text-xl"/>
            </label>
        </div>




  );
}

export default ThemeContext;
